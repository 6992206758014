<template>
  <div class="bg">
    <div class="top-banner"></div>
    <div class="top-banners"></div>
    <div class="content">
      <div class="content-one">
        <div class="content-title">
          <img src="../assets/pic/gzhj.png" alt="" />
        </div>
        <div class="content-info">
          <div class="img-top">
            <img
              class="top-l"
              src="../assets/pic/pic_recruit_picture1.png"
              alt=""
            />
            <img
              class="top-r"
              src="../assets/pic/pic_recruit_picture2.png"
              alt=""
            />
          </div>
          <div class="img-bottom">
            <img src="../assets/pic/pic_recruit_picture3.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content-two">
        <div class="content-title">
          <img src="../assets/pic/gsfl.png" alt="" />
        </div>
        <div class="content-info">
          <div class="info-item" v-for="(item, index) in benefits" :key="index">
            <div class="info-l">
              <img src="../assets/pic/gsfl_circle.png" alt="" />
            </div>
            <div class="info-r">
              <h4>{{ item.title }}</h4>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-three">
        <div class="content-title">
          <img src="../assets/pic/zpzw.png" alt="" />
        </div>
        <div class="content-info">
          <div class="info-menu">
            <div
              :class="nowBtn == 'all' ? 'btn active' : 'btn'"
              @click="change('all')"
            >
              全部
            </div>
            <div
              :class="nowBtn == 'jishu' ? 'btn active' : 'btn'"
              @click="change('jishu')"
            >
              技术类
            </div>
            <div
              :class="nowBtn == 'xiaoshou' ? 'btn active' : 'btn'"
              @click="change('xiaoshou')"
            >
              销售类
            </div>
            <div
              :class="nowBtn == 'yunyin' ? 'btn active' : 'btn'"
              @click="change('yunyin')"
            >
              运营类
            </div>
          </div>
          <div class="info-list">
            <div class="item" v-for="(item, index) in newList" :key="index">
              <div class="item-title">
                <span>{{ item.title }}</span>
                <div @click="details(item)" v-if="!item.show">
                  <img src="../assets/pic/icon_recruit_open.png" alt="" />
                </div>
                <div @click="details(item)" v-else>
                  <img src="../assets/pic/icon_recruit_cancel.png" alt="" />
                </div>
              </div>
              <div class="item-text" v-if="item.show">
                <div class="text-top">
                  <div class="top-title">岗位职责说明</div>
                  <div class="top-content">
                    <p v-for="(top, topIndex) in item.top" :key="topIndex">
                      {{ top }}
                    </p>
                  </div>
                </div>
                <div class="text-bottom">
                  <div class="bottom-title">岗位要求</div>
                  <div class="bottom-content">
                    <p
                      v-for="(bottom, botIndex) in item.bottom"
                      :key="botIndex"
                    >
                      {{ bottom }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="item-btn" v-if="!item.show" @click="details(item)">
                查看详情
              </div>
              <div class="item-btn" v-else @click="details(item)">收起详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        {
          title: "员工体检 ",
          text: "任职一年200，任职两年260，任职三年的是350，可以逐年累加",
        },
        {
          title: "员工慰问",
          text: "婚殇嫁娶、子女升学、女职工产后慰问、家庭变故慰问等等",
        },
        {
          title: "晋升空间",
          text: "横向纵向双向选择发展，每半年/一年集中考核晋升",
        },
        {
          title: "假期",
          text: "年假、法定假期",
        },
      ],
      newList: [],
      list: [
        // php工程师
        {
          title: "php工程师",
          top: [
            "1、负责服务端代码的开发；",
            "2、根据需求和分配的工作，完成程序的编码、调试、配置等工作；",
            "3、程序维护，解决技术故障和消除系统中的缺陷。",
          ],
          bottom: [
            "1、计算机相关专业，2年以上PHP开发经验;",
            "2、熟练掌握thinkphp、yii2等PHP框架的一种或者多种，并了解框架内部的运行机制；",
            "3、熟练使用mysql等数据库，并能对数据库进行性能优化，有高并发高流量场景经验优先；",
            "4、熟悉中间件技术与Web平台集成和调优技术，如消息队列、缓存、日志、报表、监控等；",
            "5、熟悉PHP编编码规范，有良好文档编写能力和编程风格；",
            "6、熟练掌握git、svn代码管理工具；",
            "7、有微信小程序开发经验者优先。",
          ],
          show: false,
          type: "jishu",
        },
        // 软件销售
        {
          title: "软件销售",
          top: [
            "1、了解软件市场趋势、变化，及时掌握行业信息；",
            "2、积极寻找客户和收集客户信息，进行客户关系的维护；",
            "3、负责市场拓展和销售工作，保持与大客户的良好合作关系；",
            "4、完成公司区域销售目标，拉动、拓展合作公司的市场占有率；",
            "5、负责渠道销售目标达成及客户发展数量达成；",
            "6、完成领导交办的其他工作。",
          ],
          bottom: [
            "1、工作有激情，认真负责，沟通协作意识良好，能够承担工作压力，工作踏实沉稳，有良好的学习、分析、解决问题的能力；",
            "2、热爱软件销售工作并有相关工作经验，思维清晰，沟通交流能力强；",
            "3、熟练运用office办公软件；",
            "4、适应出差（省内部分地区当天往返，部分地区约需1-3天）。",
          ],
          show: false,
          type: "xiaoshou",
        },
        // 多媒体运营
        {
          title: "多媒体运营",
          top: [
            "1、制定运营计划，与各部门协调进行日常运营，推动持续运营及应用服务质量持续改进；",
            "2、负责用户体验改善，各操作流程优化；",
            "3、输出日常多媒体运营状况数据，拟定营销方案并落地实施；",
            "4、负责对接合作伙伴的业务需求，完成需求分析，并输出产品需求；",
            "5、负责产品的二次包装，跟踪宣传推广进度；",
            "6、跟踪市场、行业、竞争对手动态，进行调研分析并产出分析报告，具备商业思维更佳。",
          ],
          bottom: [
            "1、本科及以上学历；",
            "2、2年以上多媒体运营工作经验；",
            "3、良好的市场分析、客户分析、推广及策划能力，平台维护协调能力；",
            "4、逻辑清晰，自主性及学习力强，且具备较强的业务敏感度及夸部门沟通协作能力；",
            "5、了解房地产金融、保险行业相关业务背景加分。",
          ],
          show: false,
          type: "yunyin",
        },
        // 安卓工程师：
        {
          title: "安卓工程师",
          top: [
            "1、负责Android平台软件模块开发和调试；",
            "2、根据需求和分配的工作，完成程序的编码、调试、配置等工作；",
            "3、参与讨论软件模块功能、操作流程及界面布局等客户端设计；负责优化移动产品的质量、性能、提升用户体验；",
            "4、解决项目过程中的各种故障和突发问题；",
            "5、参与团队技术交流和分享活动，与团队共同进步。",
          ],
          bottom: [
            "1.大学本科及以上学历，2年以上android开发经验；",
            "2.熟悉Android OS系统体系结构framework以及底层库；",
            "3.熟悉Android平台UI设计，熟悉常用布局；",
            "4.理解面向对象设计的基本原则，熟悉常用的设计模式；",
            "5.熟悉Json/XML 解析，熟悉HTTP请求；",
            "6.熟练使用java与js语言，了解react-native与原生混合开发；",
            "7、懂安卓与IOS开发的优先。",
          ],
          show: false,
          type: "jishu",
        },
        // 中级web工程师
        {
          title: "中级web工程师",
          top: [
            "1、分析需求，完成产品前端界面设计开；",
            "2、解决兼容性问题，优化前端性能，改善用户体验；",
            "3、统一规划可重用组件，规范前端模块化开发。",
          ],
          bottom: [
            "1、2年及以上前端开发经验；",
            "2、熟练掌握HTML5/CSS3/Javascript/jQuery/ React/Vue/TypeScript/webpack等前端技术，熟悉前端模块化、组件化、工程化开发；",
            "3、熟悉各种浏览器平台的特性，能够较好地解决兼容问题；了解响应式与自适应布局；",
            "4、熟练使用git版本控制系统；",
            "5、积极学习前沿技术和创新良好的技术视野，对前端工程化、体验优化与性能优化有深刻理解；",
            "6、良好的团队合作精神。",
          ],
          show: false,
          type: "jishu",
        },
        // 产品经理
        {
          title: "产品经理",
          top: [
            "1、负责具交易类、客户管理类项目原型设计，以B端链路为主，PC端和移动端兼顾设计；",
            "2、独立胜任行业类客户产品的沟通，需求分析、系统解决方案的提供、业务部门需求对接、原型设计等工作；",
            "3、定期与业务需求方进行沟通，结合公司战略规划及业务需求，组织产品设计与项目跟进管理，能够深入挖掘客户需求，协助拓宽公司市场份额；",
            "4、负责所属项目的推动、控制、协调、跟进产品功能的开发、测试、上线、培训及客户使用跟踪和答疑；",
            "5、负责所属产品上线后的业务效果跟踪， 功能优化、用户体验升级等内容。",
          ],
          bottom: [
            "1、大专及以上学历，有良好的职业操守，品行优秀，综合素质高；",
            "2、具有两年以上产品设计及项目经验，熟练掌握原型设计工具及用户交互设计，有B端产品经验者优先；",
            "3、具备对互联网产品的敏锐嗅觉、较强的产品设计能力及业务需求分析能力，能独立进行产品策划及项目跟踪，有汽车金融产品经验者优先；",
            "4、逻辑思维能力强，具备良好的分析能力、文案能力及沟通能力;",
            "5、执行能力强，有创新精神，细致认真,工作责任心强，具备团队合作精神。",
          ],
          show: false,
          type: "yunyin",
        },
        // 测试工程师
        {
          title: "测试工程师",
          top: [
            "1、 参与相关系统的需求分析，设计及撰写测试用例；",
            "2、执行测试用例，跟踪和定位产品中的缺陷或问题，完整地记录测试过程及结果；",
            "3、分析测试结果，完成测试报告，并分析原因，不断优化测试过程；",
            "4、不断学习和优化测试方法，同时能够了解业界前沿的测试技术，并在实际项目中实践和推广。",
          ],
          bottom: [
            "1、大专以上学历，计算机、软件、通信等相关专业，有相关工作经验；",
            "2、熟悉计算机网络、Linux操作系统、数据库等；",
            "3、熟悉Python或Java语言；",
            "4、有极强的责任心，执行力和一定的抗压能力。",
          ],
          show: false,
          type: "jishu",
        },
        // 中级java工程师
        {
          title: "中级java工程师",
          top: [
            "1、负责基于springCloud服务化架构的业务系统平台的技术研发；",
            "2、负责新技术、新方法的研究、应用；",
            "3、深入理解业务需求，提供高效的编码实现方案；",
            "4、根据产品功能模块设计，编码实现各模块功能，并确保开发质量；",
            "5、参与平台框架研究，设计和实现、关键技术验证等工作。",
          ],
          bottom: [
            "1、三年以上JAVA开发经验，有独立完成项目经验和能力；",
            "2、扎实的Java编程基础，熟悉常用设计模式及面向对象设计开发；",
            "3、熟练使用SpringBoot及SpringCloud全栈相关微服务技术框架的实际应用，有成熟的商业化实践案例；",
            "4、精通MySQL数据库的开发，熟练运用数据库建模工具；",
            "5、熟练使用Redis, RabbitMQ, MongoDB, ElasticSearch等相关或类似技术；",
            "6、熟练使用JQuery、H5 、Html、JavaScript、Ajax等前端框架技术；",
            "7、熟练使用Linux（CentOS/Ubuntu)操作和管理；",
            "8、熟练使用Spring、SpringMVC、Mybatis或其他主流JAVA框架；",
            "9、精通Tomcat，Apache，Nginx 等web服务器；",
            "10、有SaaS平台研发经验加分。",
          ],
          show: false,
          type: "jishu",
        },
        // 高级web工程师
        {
          title: "高级web工程师",
          top: [
            "1、分析需求，完成产品前端界面设计开发；",
            "2、解决兼容性问题，优化前端性能，改善用户体验；",
            "3、统一规划可重用组件，规范前端模块化开发。",
          ],
          bottom: [
            "1、3年及以上前端开发经验；",
            "2、熟练掌握HTML5/CSS3/Javascript/jQuery/ React/Vue/TypeScript/webpack等前端技术；熟悉前端模块化、组件化、工程化开发；",
            "3、熟悉各种浏览器平台的特性，能够较好地解决兼容问题；了解响应式与自适应布局；",
            "4、熟练使用git版本控制系统；",
            "5、积极学习前沿技术和创新良好的技术视野，对前端工程化、体验优化与性能优化有深刻理解；",
            "6、良好的团队合作精神。",
          ],
          show: false,
          type: "jishu",
        },
        // 初级java工程师
        {
          title: "初级java工程师",
          top: [
            "1、负责代码设计、开发、单元测试；",
            "2、参与项目开发与日常维护，实现相关模块功能；",
            "3、根据产品需求进行软件设计和编码实现及相关文档的编写；",
            "4、参与系统稳定性、性能和扩展性调试，不断优化改善系统。",
          ],
          bottom: [
            "1、全日制本科毕业，计算机、软件工程、通信、电子信息相关专业优先，在校有相关技术开发项目经验；",
            "2、扎实的java基础，掌握JAVA、SpringMVC、springboot、springcloud，数据库Oracle、Mysql；熟悉软件工程的各种概念，了解软件开发流程，掌握相关的设计、开发工具；",
            "3、有良好的沟通和学习能力，强烈的工作责任心和良好的团队协作能力；",
            "4、对软件开发有深厚的兴趣，喜欢钻研技术，乐于接受工作挑战。",
          ],
          show: false,
          type: "jishu",
        },
        // 开发经理
        {
          title: "开发经理",
          top: [
            "1、作为核心人员，参与软件项目的设计、开发，撰写详细设计文档；",
            "2、把握开发项目的技术细节，处理各种技术问题；",
            "3、组织编写各种技术文档、团队技术指导；",
            "4、给开发人员分配开发任务并行任务跟踪。",
          ],
          bottom: [
            "1、计算机或相关专业本科以上，3年以上相关工作经验；",
            "2、精通软件架构设计理论和实践，掌握可重用架构机制和模式（如SpringCloud等架构等），精通软件设计理论和开发技术，包括面向对象的分析设计技术、基于组件的开发技术、统一建模语言和数据库设计等；",
            "3、掌握软件工程理论和方法，精通软件开发过程，独立承担过二个以上大型项目的设计开发工作；",
            "4、具备大、中型项目系统设计的系统规划和开发能力，包括客户需求分析、项目可行性分析等；",
            "5、精通数据库开发，具有Mysql、SQLServer等主流数据库开发应验；",
            "6、具备分析、模拟、评估系统体系结构、功能、性能和效益等能力，熟悉UML语言和工具；",
            "7、熟悉主流框架结构，在实际项目中具有丰富的实战经验。",
          ],
          show: false,
          type: "jishu",
        },
      ],
      nowBtn: "all",
    };
  },
  created() {
    this.newList = this.list;
  },
  methods: {
    details(e) {
      e.show = !e.show;
    },
    change(e) {
      this.nowBtn = e;
      if (e == "all") {
        this.newList = this.list;
      } else {
        this.newList = this.list.filter((item) => {
          return item.type == e;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  min-width: 1000px;
  background-color: #061c36;
  .top-banner {
    width: 100%;
    height: 80px;
    background-color: #08192F;
  }
  .top-banners {
    width: 100%;
    height: 261px;
    background: url("../assets/pic/pic_recruit_join us.png") center center
      no-repeat;
  }
  .content {
    width: 1000px;
    margin: auto;
    padding-top: 40px;
    .content-one {
      .content-title {
        width: 282px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content-info {
        margin-top: 20px;
        .img-top {
          width: 100%;
          .top-l {
            width: 584px;
            height: auto;
            margin-right: 6px;
            display: inline-block;
          }
          .top-r {
            width: 410px;
            height: auto;
            display: inline-block;
          }
        }
        .img-bottom {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            display: inline-block;
          }
        }
      }
    }
    .content-two {
      width: 100%;
      margin-top: 40px;
      .content-title {
        width: 271px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content-info {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .info-item {
          width: 50%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 23px;
          color: #ffffff;
          .info-l {
            width: 24px;
            height: 24px;
            margin-top: 5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info-r {
            width: calc(100% - 50px);
            margin-left: 15px;
            h4 {
              font-size: 22px;
              font-weight: 400;
              margin-bottom: 10px;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #cccccc;
            }
          }
        }
      }
    }
    .content-three {
      width: 100%;
      margin-top: 30px;
      .content-title {
        width: 314px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content-info {
        .info-menu {
          width: 100%;
          padding: 30px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .btn {
            width: 72px;
            height: 38px;
            background-color: #083c7b;
            font-size: 16px;
            font-weight: 400;
            margin-right: 20px;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
            color: #ffffff;
            opacity: 0.5;
            &.active {
              background-color: #388df0;
              opacity: 1;
            }
          }
        }
        .info-list {
          width: 100%;
          height: auto;
          -moz-column-count: 3; /* Firefox */
          -webkit-column-count: 3; /* Safari 和 Chrome */
          column-count: 3;
          -moz-column-gap: 20px; /* Firefox */
          -webkit-column-gap: 20px; /* Safari 和 Chrome */
          column-gap: 20px;
          padding-bottom: 20px;
          .item {
            break-inside: avoid;
            page-break-inside: avoid;
            overflow: auto;
            width: 320px;
            margin-bottom: 20px;
            background-color: #0A2E58;
            .item-title {
              width: 100%;
              box-sizing: border-box;
              padding: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              span {
                font-size: 20px;
                font-weight: 600;
                color: #ffffff;
              }
              img {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
            }
            .item-text {
              width: 100%;
              .text-top {
                padding: 20px;
                border-top: 1px solid #164084;
                .top-title {
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  margin-bottom: 20px;
                }
                .top-content {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #ffffff;
                }
              }
              .text-bottom {
                padding: 20px;
                border-top: 1px solid #164084;
                .bottom-title {
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  margin-bottom: 20px;
                }
                .bottom-content {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #ffffff;
                }
              }
            }
            .item-btn {
              width: 100%;
              height: 48px;
              line-height: 48px;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              cursor: pointer;
              color: #ffffff;
              background-color: #388DF0;
              &:hover {
                background-color: #0ea2f0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
